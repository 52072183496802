section.servicos {
    margin-top: 3em;
    width: 100%;
    max-width: 1100px;
    margin: auto;
    padding-bottom: 0;
    padding-top: 5em;
}

section.servicos > h1 {
    font-size: 2em;
    color: white;
}

div.card > p {
    color: white;
    font-weight: bolder;
    margin: 1em 1em;
}

div#servicos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 3em;
    background-color: none;
}

div.card {
    display: flex;
    flex-wrap:nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin: 10px;
}


div.container {
    display: none;
}

@media (max-width: 600px) {
    section.servicos {
        scale: 0.8;
        margin-bottom: 3em;
    }
    div#servicos {
        display: flex;
        flex-direction: column;

        align-items: center;
    }
    div.card {
        margin: 0.5rem;
        width: 100%;
    }
}
