section#home{
    width: 100%;
    height: auto;
    padding-top: 2em; 
    text-align: center;
    /* background-image: url('../../img/ttten_invert.svg'); */
    /* background-color: #323a42; */
    background-size: 1920px 1080px;
    position: relative;
    display: flex;
    flex-direction: column;
}


section#home>h1{
    margin-top: 100px;
    backdrop-filter: none;
    color: white;
}

section#home>p{
    color: white;
    font-size: 1.5em;
    max-width: 1000px;
   margin:1em auto;
}
div.componenteGrande{
background-color: rgba(255, 255, 255, 0.301);
border-radius: 10px;
backdrop-filter: blur(5px);
margin: 50px 0px; 
max-width: 1400px;
flex-grow: 4;
}

div.componenteReduzido{
    display: none;
}

div.container{
    display: flex;
    justify-content: space-between;
    

}

div.empty{
    display: block;
    background-color: #323a4200;
    width: 10px;
    height: 10px;
}
div.logos{
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 3em;
    max-width: 100%;
}
div.logos>img{
    max-width: 100px;
    align-self: flex-end;
}
div.logos>img.mercedes{
    margin: 2em;
}
 
@media (max-width: 1390px) {
    div.componenteReduzido{
        display: flex;
        transform: translate(33px)

    }
    div.componenteGrande{
        display: none;

    }
    div.container{
        display: flex;
        justify-content: space-between;
    }


}

@media (max-width: 440px){
    div.logos{
        scale: 0.8;
    }
    section#home>h1{
        scale: 0.8;
    }

    section#home>p{
        scale: 0.8;
    }
}