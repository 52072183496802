.carousel {
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    max-height: auto;
    margin: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 10px 2em;
    
}

.slide{
    border-radius: 0.5rem;
    /* box-shadow: 0px 0px 7px #666; */
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.534);
}

#imagem-carrousel{
    object-fit: contain;
    width: 100%;
    height: 700px;
}
.slide-hidden{
    display: none;
}
.arrow {
    position: absolute;
    width: 2rem;
    height: 2rem;
    color: white;
    filter: drop-shadow(0px 0px 5px #555);
}

.arrow:hover{
    cursor: pointer;
}
.arrow-left {
    left: 1rem;
}

.arrow-right {
    right: 1rem;
}

.indicators {
    display: flex;
    position: absolute;
    bottom: 1rem;
    gap: 0.5rem;
}

.indicator{
    background-color: white;
    height: 0.5rem;
    width: 0.5rem ;
    border-radius: 100%;
    border: none;
    outline: none;
    box-shadow: 0px 0px 5px #555;
    cursor: pointer;
}
.indicator-inactive{
    background-color: grey;
}