.sobre{
    width: 100%;
    height: auto;
    padding-top: 2em; 
    text-align: center;
    
    position: relative;
    /* background-image: url('../../img/ttten.svg');
    background-size: 1920px 1080px;
    background-color: #323a42; */
    
}
.sobre>div>p{
    max-width: 1100px;
    margin: auto;
    padding: 3em 3em;
}
.sobre>h1{
    padding-top: 3em;
    font-weight: bolder;
    font-size: larger;
    font-size: 2em;
}

