.certificados{
padding: 5em 0em;
}

.certificados img{
    width: 100%; /* A imagem ocupará 100% da largura da div pai */
    height: auto; /* Mantém a proporção da imagem */
}

.certificados h1{
    padding-bottom: 1em;
    font-weight: bolder;
    font-size: larger;
    font-size: 2em;
}

.certificados p{
    padding-bottom: 2em;

}