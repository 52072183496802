@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

.App {
  text-align: center;
  height: 100%;
  width: 100%;
  background-color: #272d33;
}

#navbar{
  z-index: 1000;

}

h1{
  font-family: "Plus Jakarta Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  backdrop-filter: none;
  color: white;
}

p{
  font-family: "Plus Jakarta Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color: white;

}

.body{
  max-width: 1200px;
  margin: auto;
  background-image: url('../src/img/background.svg');
  background-color: #323A42;
  -webkit-box-shadow: 0px 0px 43px 10px rgba(0,0,0,0.51);
  -moz-box-shadow: 0px 0px 43px 10px rgba(0,0,0,0.51);
  box-shadow: 0px 0px 43px 10px rgba(0,0,0,0.51);
}

html{
  scroll-behavior: smooth;
}