
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.pic{
    width: 100%;
    height: 100vh;
    background-image: url(../../img/gt63_1_ajuste.jpg);
    background-size: cover;
}

div.tipografia{
    padding: 5em;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.644);

}

.tipografia>section.content>h1{
    font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: bold;
  font-size: 5em;
  color: #E83338;
}

.tipografia>section.content>h2{
    font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: bold;
    font-size: 3em;
    color: white;
}

div.logos{
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 3em;
    max-width: 100%;
}
div.logos>img{
    max-width: 100px;
    align-self: flex-end;
}
div.logos>img.mercedes{
    margin: 2em;
}

div.tipografia>section.content{
    bottom: -20%;
    position: relative;
}

@media (max-width: 900px) {
    .tipografia>section.content>h1{
      font-size: 1.6em;
    }
    .tipografia>section.content>h2{
        font-size: 1em;
    }
    div.logos{
        scale: 0.8;
    }
    section#home>h1{
        scale: 0.8;
    }

    section#home>p{
        scale: 0.8;
    }

    .pic{
        background-position-x: 25%;
        background-image: url(../../img/gt63.jpg);
    }
}