.mapa{
    margin: auto;
}

.mapa>h1{
    padding-bottom: 2em;
}

.mapa>p{
    padding: 0 2em;
}
iframe{
    padding-top: 2em;
}