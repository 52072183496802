div.contato{
    padding-top: 5em;
    /* background-size: 1920px 1080px;
    background-image: url(../../img/ttten.svg); */
}   

.contato>ul{
    padding-top: 3em;
    margin: auto;
}

.contato>p{
    padding: 0px 3em
}

.contato>h1{
    font-size: 2em;
    padding-bottom: 2em;
}

.contato>ul>li{
    padding: 0.3em;
    width: 100%;
    margin: auto ;
    list-style: none;
    text-align: center;
}

.contato>ul>li>a{
text-decoration: none;
text-align: center;
color: white;
background-color: #FF2A30;
padding: 0.5em;
border-radius: 0.3em;
width: 300px;
display: block;
margin: auto;
}