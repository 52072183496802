.active{
    background-color: #2C333A;
}

nav{
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #191D21;
    position: fixed;
    width: 100%;
    -webkit-box-shadow: 0px 18px 52px 0px rgba(0,0,0,0.56);
    -moz-box-shadow: 0px 18px 52px 0px rgba(0,0,0,0.56);
    box-shadow: 0px 18px 52px 0px rgba(0,0,0,0.56);
}

nav>#logo>img{
    width: 160px;
    height: auto;
}

#navbar>ul{
    display: flex;

}

#navbar>ul>li{
    list-style: none;
    padding: 0 1rem;
}

#navbar>ul>li>a{
    text-decoration: none;
    color: white;
    font-weight: bold;
    display: block;
    padding: 0.5rem;
    margin: 0 0.5rem;
    border-radius: 0.5rem;
    transition: 0.5s;
}

#navbar>ul>li>a:hover{
background-color: #797979;
color: #191D21;
    
}

nav>.menu {
display: none;
position: absolute;
top: 0.75rem;
right: 0.5rem;
flex-direction: column;
justify-content: space-between;
width: 2.25rem;
height: 2rem;
}

nav>.menu>span{
    height: 0.4rem;
    width: 100%;
    background-color: rgb(255, 255, 255) ;
    border-radius: 0.2rem;
  
}

@media (max-width: 729px) {

    #navbar>.menu{
        display: flex;
        
    }

    #navbar {
        flex-direction: column;
        align-items: start;
    }

    #navbar>ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
    }

    #navbar>ul.open{
        display: flex;
    }
    #navbar>ul>li{
        width: 100%;
    }

    #navbar>ul>li>a{
        margin: 0.2rem 0.5rem;
    }
}